<template>
    <div class="comment_box">
        <div class="comment_header">
            <span>评论管理</span>
            <el-button v-if="selectWorkObj.id" class="selectWork" @click="selectAuthorWork">选择作品</el-button>
        </div>
        <div class="has_work" v-if="selectWorkObj.id">
            <div class="show_work">
                <div class="image_box" @click="viewVideo(selectWorkObj.video_url)">
                    <img class="image" :src="selectWorkObj.cover_url" alt="">
                    <img class="playIcon" src="../../../assets/img/image/playIcon.png" alt="">
                </div>
                <div class="work_desc">
                    <div class="video_desc">
                        <div class="desc">
                            <span v-if="selectWorkObj?.desc">{{ selectWorkObj.desc
                                }}</span>
                            <span v-else style="color: #16182380;">无视频描述</span>
                        </div>
                    </div>
                    <p class="publish_date">发布于 {{ selectWorkObj.video_create_time }}</p>
                </div>

            </div>
            <div class="comment_main">
                <div class="comment_header">评论管理</div>
                <div class="comment_input">
                    <InputModule id="1" :isShow="{ isOpen: true }" placeholderValue="有爱评论,说点好听的"
                        @getSendMessage="getSendMessage" />
                </div>
                <div class="content_info_box">
                    <div class="select_box">
                        <el-select popper-class="el_sort_select" v-model="videoCotentParam.sort_type"
                            placeholder="请选择排序方式" @change="selectSort">
                            <el-option v-for="item in sortOption" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="content_list">
                        <div class="content_item" v-for="(item, index) in reviewContent">
                            <div class="item_left">
                                <div class="image_box">
                                    <img :src="item.avatar" alt="">
                                </div>
                            </div>
                            <div class="item_right">
                                <div class="content_header">
                                    <div class="content_name">
                                        <h4>{{ item.nick_name }}</h4>
                                        <div class="icon_au" v-if="item.is_author == 1">作者</div>
                                    </div>
                                    <div class="content_time">{{ item.time }}</div>

                                </div>
                                <div class="content_info">
                                    <div v-html="item.content" style="padding: 14px 0 12px;">
                                    </div>
                                    <div class="digg_box">
                                        <span class="block">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M9.542 1.75c-.99 0-1.892.395-2.44 1.142-.753-.747-1.655-1.142-2.644-1.142C2.569 1.75 1 3.314 1 5.175c0 .126.006.184 0 .19.01.429.222 1.202.392 1.527 1.144 2.365 4.758 4.87 4.928 5.033.17.163.34.325.68.325s.51-.162.68-.325c.17-.162 3.329-2.442 4.71-4.658.06-.151.1-.226.203-.38.066-.16.132-.304.204-.38.125-.428.196-.751.203-1.142-.005-.011 0-.065 0-.19 0-1.86-1.57-3.425-3.458-3.425z"
                                                    fill="#1C1F23" fill-opacity="0.3"></path>
                                            </svg>
                                            <span class="number">{{ item.digg_count }}</span>
                                        </span>
                                        <span class="block" style="cursor: pointer;" @click="openInput(item)">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M11.32 10.42c1.25-1.26 1.98-2.574 1.98-4.103C13.3 3.215 10.48.7 7 .7S.7 3.215.7 6.317C.7 9.42 3.61 11.55 7.09 11.55v.932c0 .37.384.607.708.429.907-.5 2.484-1.445 3.52-2.491zM7.93 6.425a.944.944 0 11-1.889 0 .944.944 0 011.889 0zm-3.912.945a.944.944 0 100-1.889.944.944 0 000 1.889zm6.88-.945a.944.944 0 11-1.889 0 .944.944 0 011.89 0z"
                                                    fill="#1C1F23" fill-opacity="0.3"></path>
                                            </svg>
                                            <span class="number"> 回复</span>
                                        </span>
                                    </div>
                                    <div v-show="item.isOpenInput" class="input_container">
                                        <InputModule :id="item.comment_id" @getSendMessage="getSendMessage"
                                            :placeholderValue="`回复: ${item.nick_name}`" @hideInput="hideInput">
                                        </InputModule>
                                    </div>
                                    <div class="comment_view_box">
                                        <div class="content_item" v-for="(itemC, index) in item.videoReplyComment">
                                            <div class="item_left">
                                                <div class="image_box">
                                                    <img :src="itemC.avatar" alt="">
                                                </div>
                                            </div>
                                            <div class="item_right">
                                                <div class="content_header">
                                                    <div class="content_name">
                                                        <h4>{{ itemC.nick_name }}</h4>
                                                        <div class="icon_au" v-if="itemC.is_author == 1">作者</div>
                                                    </div>
                                                    <div class="content_time">{{ itemC.time }}</div>
                                                </div>
                                                <div class="content_info">
                                                    <div v-html="itemC.content" style="padding: 14px 0 12px;"></div>
                                                    <div class="digg_box">
                                                        <span class="block">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M9.542 1.75c-.99 0-1.892.395-2.44 1.142-.753-.747-1.655-1.142-2.644-1.142C2.569 1.75 1 3.314 1 5.175c0 .126.006.184 0 .19.01.429.222 1.202.392 1.527 1.144 2.365 4.758 4.87 4.928 5.033.17.163.34.325.68.325s.51-.162.68-.325c.17-.162 3.329-2.442 4.71-4.658.06-.151.1-.226.203-.38.066-.16.132-.304.204-.38.125-.428.196-.751.203-1.142-.005-.011 0-.065 0-.19 0-1.86-1.57-3.425-3.458-3.425z"
                                                                    fill="#1C1F23" fill-opacity="0.3"></path>
                                                            </svg>
                                                            <span class="number">{{ itemC.digg_count }}</span>
                                                        </span>
                                                        <span class="block" style="cursor: pointer;"
                                                            @click="openReplayInput(item, itemC)">
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M11.32 10.42c1.25-1.26 1.98-2.574 1.98-4.103C13.3 3.215 10.48.7 7 .7S.7 3.215.7 6.317C.7 9.42 3.61 11.55 7.09 11.55v.932c0 .37.384.607.708.429.907-.5 2.484-1.445 3.52-2.491zM7.93 6.425a.944.944 0 11-1.889 0 .944.944 0 011.889 0zm-3.912.945a.944.944 0 100-1.889.944.944 0 000 1.889zm6.88-.945a.944.944 0 11-1.889 0 .944.944 0 011.89 0z"
                                                                    fill="#1C1F23" fill-opacity="0.3"></path>
                                                            </svg>
                                                            <span class="number"> 回复</span>
                                                        </span>
                                                    </div>
                                                    <div v-show="itemC.isOpenInput" class="input_container">
                                                        <InputModule :id="itemC.comment_id"
                                                            :ref="`ref${itemC.comment_id}`"
                                                            @getSendMessage="getReplaySendMessage"
                                                            :placeholderValue="`回复: ${itemC.nick_name || ''}`"
                                                            @hideInput="hideReplayInput">
                                                        </InputModule>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tip_option" v-if="item.reply_comment_total > 0">
                                        <span class="line"></span>
                                        <span style="cursor: pointer;" @click="openVidepReplay(item)"
                                            v-if="item.videoReplyComment.length < item.reply_comment_total">展开{{
                                                item.reply_comment_total - item.videoReplyComment.length }}条回复 <i
                                                class="el-icon-arrow-down"></i></span>
                                        <span style="cursor: pointer;" @click="closeVidepReplay(item)" v-else>收起回复 <i
                                                class="el-icon-arrow-up"></i></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>

                        </div>

                    </div>


                </div>

            </div>
        </div>
        <div class="no_work" v-if="!selectWorkObj.id">
            <img src="../../../assets/img/work/noData.png" alt="">
            <span>你还没有发布视频</span>
        </div>
        <el-drawer title="我是标题" :visible.sync="drawerViasible" :with-header="false" size="448px">
            <div class="drawer">
                <div class="header">
                    <span class="header_title">作品列表</span>
                    <span>共个{{ param.total }}视频</span>

                </div>
                <div class="show_item_work" :class="activeWorkIndex == index ? 'active_show_item_work' : ''"
                    v-for="(item, index) in workList" @click="selectWork(item, index)">
                    <div class="image_box">
                        <img class="image" :src="item.cover_url" alt="">
                        <!-- <img class="playIcon" src="../../../assets/img/image/playIcon.png" alt=""> -->
                    </div>
                    <div class="work_desc">
                        <div class="video_desc">
                            <div class="desc">
                                <span v-if="item?.desc">{{ item.desc }}</span>
                                <span v-else style="color: #16182380;">无视频描述</span>
                            </div>
                        </div>
                        <p class="publish_date">发布于 {{ item.video_create_time }}</p>
                    </div>
                    <div class="comment_number">
                        <svg width="20" height="20" viewBox="0 0 14 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M11.32 10.42c1.25-1.26 1.98-2.574 1.98-4.103C13.3 3.215 10.48.7 7 .7S.7 3.215.7 6.317C.7 9.42 3.61 11.55 7.09 11.55v.932c0 .37.384.607.708.429.907-.5 2.484-1.445 3.52-2.491zM7.93 6.425a.944.944 0 11-1.889 0 .944.944 0 011.889 0zm-3.912.945a.944.944 0 100-1.889.944.944 0 000 1.889zm6.88-.945a.944.944 0 11-1.889 0 .944.944 0 011.89 0z"
                                fill="#1C1F23" fill-opacity="0.3"></path>
                        </svg>
                        <span>{{ item.comment_count }}</span>
                    </div>

                </div>
                <div class="more_work">
                    <span v-if="isLoading" style="cursor: pointer;" @click="getMore">点击加载更多</span>
                    <span v-else>暂无更多作品</span>

                </div>


            </div>
        </el-drawer>
    </div>
</template>
<script>
import InputModule from "../../../components/creativecentermodule/InputModule.vue"
import emojsList from "@/utils/emojis"
export default {
    props: ["accountId"],
    components: {
        InputModule
    },
    data() {
        return {
            selectWorkObj: {},
            workList: [],
            reviewContent: [],
            drawerViasible: false,
            isLoading: true,
            param: {
                page: 1,
                limit: 8,
                total: 0
            },
            activeWorkIndex: 0,
            commentValue: "",
            showDialog: false,
            sortOption: [
                {
                    value: 1,
                    label: "按点赞量排序"
                },
                {
                    value: 2,
                    label: "按点评论时间排序"
                },
            ],
            videoCotentParam: {
                page: 1,
                limit: 10,
                id: null,
                sort_type: null
            },//视频评论参数
            replayParm: {
                page: 1,
                limit: 5,
                id: null,
                comment_id: null,
            },//视频评论回复列表
            comment_id: null,//当前评论的id
            emojsList: emojsList,
            videoReplyComment: []





        }
    },
    watch: {
        'accountId': function (newval, oldVal) {
            // console.log(newval, "切换账号");
            this.workList = []
            this.selectWorkObj = {}
            this.drawerViasible = false
            this.isLoading = true
            this.getWorkList()
        }
    },
    mounted() {
        this.getWorkList()
    },
    methods: {
        selectAuthorWork() {
            this.drawerViasible = true


        },
        // 获取作品列表
        getWorkList() {
            let param = {
                paging: 1,
                page: this.param.page,
                pageSize: this.param.limit,
                account_id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, param, (res) => {
                if (res.code == 200) {
                    res.data.list.forEach(item => {
                        this.workList.push(item)
                    })
                    this.param.total = res.data.total
                    this.selectWorkObj = this.selectWorkObj.id ? this.selectWorkObj : this.workList[0]
                    this.videoCotentParam.id = this.selectWorkObj.id
                    this.replayParm.id = this.selectWorkObj.id
                    if (this.param.page * this.param.limit > res.data.total)
                        this.isLoading = false
                    // 获取评论列表
                    this.getMessageList()
                }
            }, (err) => {
                // console.log(err);
            })
        },
        // 回复评论
        ReplyReview() {

        },
        viewVideo(url) {
            window.open(url)
        },
        selectWork(item, index) {
            this.activeWorkIndex = index
            this.selectWorkObj = item
            this.videoCotentParam.id = this.selectWorkObj.id
            this.replayParm.id = this.selectWorkObj.id
            this.videoCotentParam.sort_type = null
            this.drawerViasible = false
            this.getMessageList()

        },
        getMore() {
            // 点击加载更多
            this.param.page = this.param.page + 1
            this.getWorkList()
        },
        getSendMessage(val, id) {
            // console.log("发送信息了", val);
            this.commentValue = val
            this.comment_id = id
            this.postMessage()
        },
        getReplaySendMessage(val, id) {
            // 回复评论的评论调用的函数
            this.commentValue = val
            this.comment_id = id
            let param = {
                id: this.selectWorkObj.id,
                content: val,
                comment_id: id
            }
            this.$httpStudent.axiosPost(this.$api.tiktokInteractionComment, param, (res) => {
                if (res.code == 200) {
                    this.replayParm.id = this.selectWorkObj.id
                    this.reviewContent.forEach((item, index) => {
                        if (item.videoReplyComment.length > 0) {
                            item.videoReplyComment.forEach((item1, index1) => {
                                if (item1.comment_id == id) {
                                    this.replayParm.comment_id = item.comment_id
                                }
                            })
                        }
                    })
                    this.replayParm.page = 1
                    setTimeout(() => {
                        this.getMessageReplayList()
                    }, 2000)
                    this.hideReplayInput(id)
                }
            }, (err) => {
                console.log(err);
            })
        },
        // 请求发送信息
        postMessage() {
            let param = {
                id: this.selectWorkObj.id,
                content: this.commentValue,
            }
            if (this.comment_id != 1) {
                param.comment_id = this.comment_id
            }
            this.$httpStudent.axiosPost(this.$api.tiktokInteractionComment, param, (res) => {
                if (res.code == 200) {
                    if (this.comment_id != 1 && this.comment_id) {
                        this.replayParm.id = this.selectWorkObj.id
                        this.replayParm.comment_id = this.comment_id
                        this.hideInput(this.comment_id)
                        // 刷新评论回复列表 
                        setTimeout(() => {
                            this.getMessageReplayList()
                        }, 2000)
                    } else {
                        // 刷新评论列表
                        setTimeout(() => {
                            this.getMessageList()
                        }, 2000)

                    }
                }
            }, (err) => {
                // console.log(err);
            })

        },
        // 获取到评论列表
        getMessageList() {
            let param = {
                ...this.videoCotentParam
            }
            this.$httpStudent.axiosGetBy(this.$api.tiktokInteractionItemComment, param, (res) => {
                if (res.code == 200) {
                    this.reviewContent = res.data.list
                    this.reviewContent.forEach(item => {
                        // emojsList
                        item.content = this.formatText(item.content, this.emojsList)
                        item.isOpenInput = false
                        item.videoReplyComment = []
                        item.replayParm = {
                            page: 1,
                            limit: 5,
                            comment_id: item.comment_id,
                        }
                    })
                } else {
                    this.$message.warning(res.message)
                }


            }, (err) => {
                // console.log(err);
            })



        },
        getMessageReplayList() {
            let param = {
                ... this.replayParm
            }
            this.$httpStudent.axiosGetBy(this.$api.tiktokInteractionItemReply, param, (res) => {
                if (res.code == 200) {
                    this.videoReplyComment = res.data.list
                    this.videoReplyComment.forEach(item => {
                        item.content = this.formatText(item.content, this.emojsList)
                        if (item.reply_nick_name) {
                            item.content = `回复 <em style="color:#1c1f2399">${item.reply_nick_name}</em>: ${item.content}`
                        }
                        item.isOpenInput = false
                    })
                    let obj = this.reviewContent.find(item => {
                        return item.comment_id == this.replayParm.comment_id
                    })
                    let index = this.reviewContent.findIndex(item => {
                        return item.comment_id == this.replayParm.comment_id
                    })
                    if (this.replayParm.page != 1) {
                        obj.videoReplyComment.push(...this.videoReplyComment)
                    } else {
                        obj.videoReplyComment = []
                        obj.videoReplyComment.push(...this.videoReplyComment)
                        obj.replayParm.page = 1
                    }
                    obj.reply_comment_total = res.data.total
                    this.reviewContent.splice(index, 1, obj)
                }

            }, (err) => {
                // console.log(err);
            })
        },
        // 刷新评论列表
        refreshComments() {
            let param = {
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.tiktokInteractionRefreshComment, param, (res) => {
            }, (err) => {
                // console.log(err);
            })

        },
        formatText(input, imageMap) {
            // console.log(imageMap,"imageMap");
            let formattedText = input.replace(/\n/g, '<br>');
            // formattedText = '<p">' + formattedText + '</p>';
            const imageMapObj = {};
            imageMap.forEach(item => {
                imageMapObj[item.name] = item.url;
            });
            // console.log(imageMapObj, "imageMapObj");

            formattedText = formattedText.replace(/\[.*?\]/g, (match) => {
                if (imageMapObj[match]) {
                    return `<img src="${imageMapObj[match]}" alt="${match}" style="width:21px;height:20px">`;
                } else {
                    return match;
                }
            });

            return formattedText;
        },
        openInput(obj) {
            // 打开回复的弹框
            let data = this.reviewContent.find(item => item.comment_id == obj.comment_id)
            let index = this.reviewContent.findIndex(item => item.comment_id == obj.comment_id)
            data.isOpenInput = !data.isOpenInput
            // this.$set(data, "isOpenInput", !data.isOpenInput);
            this.reviewContent.splice(index, 1, data)
            // console.log(this.reviewContent, "this.reviewContent");

        },
        openReplayInput(item, itemC) {
            let obj = item.videoReplyComment.find(data => data.comment_id == itemC.comment_id)
            obj.isOpenInput = !obj.isOpenInput
            let index = this.reviewContent.findIndex(e => item.comment_id == e.comment_id)
            // console.log(item);
            this.reviewContent.splice(index, 1, item)
            let ref = `ref${itemC.comment_id}`
            // console.log(this.$refs[ref][0]);

            this.$refs[ref][0].initQuill()



        },
        hideReplayInput(id) {
            let ind = 1
            let obj = {}
            this.reviewContent.forEach((item, index) => {
                if (item.videoReplyComment.length > 0) {
                    item.videoReplyComment.forEach((item1, index1) => {
                        if (item1.comment_id == id) {
                            item1.isOpenInput = false
                            obj = item
                            ind = index
                        }

                    })
                }

            })
            this.reviewContent.splice(ind, 1, obj)

        },
        hideInput(id) {
            let data = this.reviewContent.find(item => item.comment_id == id)
            let index = this.reviewContent.findIndex(item => item.comment_id == id)
            data.isOpenInput = false
            this.reviewContent.splice(index, 1, data)
        },
        openVidepReplay(item) {

            let obj = this.reviewContent.find(data => data.comment_id == item.comment_id)
            if (item.videoReplyComment.length > 0) {
                this.$set(obj.replayParm, 'page', obj.replayParm.page + 1)
            }
            this.replayParm.comment_id = item.comment_id
            this.replayParm.page = item.replayParm.page

            this.getMessageReplayList()

            // obj.replayParm.page = obj.replayParm.page + 1

        },
        closeVidepReplay(item) {
            let obj = this.reviewContent.find(data => data.comment_id == item.comment_id)
            obj.videoReplyComment = []
            obj.replayParm.page = 1
            let index = this.reviewContent.findIndex(data => data.comment_id == item.comment_id)
            this.reviewContent.splice(index, 1, obj)

        },
        selectSort(val) {
            this.videoCotentParam.sort_type = val
            // console.log(this.videoCotentParam.sort_type, " this.videoCotentParam.sort_type");
            this.getMessageList()


        }

    }
}


</script>
<style lang="scss" scoped>
.comment_box {

    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fff;

    .comment_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            padding: 0;
            margin: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
            color: #161823;
        }

        .selectWork {
            width: 80px;
            height: 32px;
            border: none;
            border-radius: 4px;
            background-color: #fe2c55;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: bold;
        }

    }

    .has_work {

        .show_work {

            margin-top: 20px;
            width: 100%;
            height: 160px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;

            background-color: #16182305;
            border-radius: 4px;

            .image_box {
                width: 60px;
                height: 80px;
                border-radius: 2;
                position: relative;
                cursor: pointer;

                .image {
                    width: 100%;
                    height: 100%;
                    border-radius: 2;
                    object-fit: cover;
                }

                .playIcon {
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                }

            }

            .work_desc {
                flex: 1;
                width: 0;
                height: 120px;
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                box-sizing: border-box;

                // justify-content: space-between;
                .video_desc {

                    width: 100%;
                    height: 100px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26px;
                    color: #161823;

                    .desc {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5;
                        max-height: 3em;
                    }


                }

                .publish_date {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    color: #333;
                    mix-blend-mode: normal;
                    opacity: .4;
                    padding: 0;
                    margin: 0;
                }
            }

        }

        .comment_main {
            width: 100%;
            padding-bottom: 20px;

            .comment_header {
                width: 100%;
                height: 61px;
                line-height: 61px;
                border-bottom: 1px solid #f2f2f4;
                font-weight: 600;
                color: #1c1f23e6;
                font-size: 18px;

            }

            .comment_input {
                width: 100%;
                display: flex;
                padding: 24px 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #f2f2f4;
            }

            .content_info_box {
                width: 100%;

                .select_box {
                    width: 100%;
                    padding: 20px 0;
                    box-sizing: border-box;
                    padding-bottom: 40px;

                    ::v-deep .el-input__inner {
                        width: 165px;
                        background-color: #f2f2f4;
                        color: #252632;
                        border: none;
                        padding: 0px 10px 0px 16px;
                        height: 30px;
                        box-sizing: border-box;
                    }

                    ::v-deep .el-select .el-input .el-select__caret {
                        color: #252632;
                    }

                    ::v-deep .el-input__icon {
                        line-height: 30px;
                    }

                }

                .content_list {
                    .content_item {
                        width: 100%;
                        padding: 24px 0 17px 20px;
                        box-sizing: border-box;
                        display: flex;

                        .item_left {
                            width: 40px;

                            .image_box {
                                width: 40px;
                                height: 40px;
                                border-radius: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 100%;
                                }
                            }


                        }

                        .item_right {
                            flex: 1;
                            width: 0;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: column;
                            margin-left: 10px;

                            .content_header {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                .content_name {
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    line-height: 20px;

                                    h4 {
                                        padding: 0;
                                        margin: 0;
                                    }

                                    .icon_au {
                                        background: #fe2c55;
                                        color: white;
                                        // min-width: 34px;
                                        height: 17px;
                                        line-height: 17px;
                                        padding: 0px 8px;
                                        border-radius: 2px;
                                        margin-left: 5px;
                                        font-size: 12px;
                                        text-align: center;
                                    }
                                }

                                .content_time {
                                    font-size: 12px;
                                    margin: 0 0 3px;
                                    line-height: 20px;
                                    color: #1c1f2399;
                                }



                            }

                            .content_info {
                                .digg_box {
                                    // margin-top: 10px;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    color: rgba(28, 31, 35, 0.6);

                                    .block {
                                        width: 60px;
                                        display: flex;
                                        align-items: center;

                                        // margin-right: 20px;
                                        .number {
                                            margin-left: 5px;
                                        }
                                    }
                                }

                                .input_container {
                                    width: 100%;
                                    padding: 10px 0;
                                    box-sizing: border-box;
                                }

                                .tip_option {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    color: rgba(28, 31, 35, 0.6);
                                    margin-top: 8px;
                                    cursor: pointer;
                                    font-size: 12px;
                                    padding: 10px 0;
                                    box-sizing: border-box;

                                    .line {
                                        width: 32px;
                                        height: 1px;
                                        display: inline-block;
                                        margin-right: 4px;
                                        background: rgba(22, 24, 35, 0.06);
                                    }
                                }
                            }

                        }


                    }

                }

            }

        }
    }

    .no_work {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }



}

.drawer {
    padding: 20px;
    box-sizing: border-box;

    .header {
        padding: 0px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #33333380;
        padding-bottom: 20px;

        .header_title {
            font-size: 20px;
            color: #161823;
        }
    }

    .active_show_item_work {
        background-color: #16182305;
    }

    .show_item_work {

        width: 100%;

        padding: 14px 16px;
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        // border: 1px solid #666;

        border-radius: 4px;

        .image_box {
            width: 60px;
            height: 80px;
            border-radius: 2px;


            .image {
                width: 100%;
                height: 100%;
                border-radius: 2;
                // overflow-clip-margin: content-box;
                // overflow: clip;
                object-fit: cover;

            }

        }

        .work_desc {
            flex: 1;
            width: 0;
            height: 80px;
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            justify-content: space-between;

            p {
                padding: 0;
                margin: 0;
            }

            .video_desc {
                width: 100%;
                // height: 60px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                color: #161823;
                padding-right: 10px;
                box-sizing: border-box;

                .desc {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.5;
                    max-height: 3em;
                }
            }

            .publish_date {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: #333;
                mix-blend-mode: normal;
                opacity: .4;
            }
        }

        .comment_number {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #1c1f2399;
            font-size: 12px;
        }


    }

    .more_work {
        width: 100%;
        height: 60px;
        text-align: center;
        line-height: 60px;

    }
}
</style>
<style>
.el_sort_select {
    .el-select-dropdown__item.selected {
        color: #252632;
        font-weight: 600;

        background-color: #f2f2f4;
    }
}
</style>